<template>
  <component :is="getRatingHistoryItemComponent" :rating="rating" :isOpenDetail="isOpenDetail" />
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent, ref, toRefs } from 'vue';

import { RatingHistory } from '@/enums/rating.enum';
import type { IRatingHistoryItem } from '@/types/rating/rating.response.type';

const props = defineProps<{
  rating: IRatingHistoryItem;
}>();

const { rating } = toRefs(props);

const isOpenDetail = ref<boolean>(false);

const getRatingHistoryItemComponent = computed(() => {
  const globalRatingTypes = [
    RatingHistory.GLOBAL_RATING_REGISTER,
    RatingHistory.GLOBAL_RATING_MODIFY,
    RatingHistory.GLOBAL_RATING_DELETE
  ];

  if (globalRatingTypes.includes(rating.value.type)) {
    return defineAsyncComponent(
      () => import('@/components/rating/rating-history-item-global-rating.vue')
    );
  }

  const deletedRatingTypes = [RatingHistory.GRAC_RATING_DELETE, RatingHistory.RATING_DELETE];

  if (deletedRatingTypes.includes(rating.value.type)) {
    return defineAsyncComponent(() => import('@/components/rating/rating-history-item-delete.vue'));
  }

  return defineAsyncComponent(() => import('@/components/rating/rating-history-item-others.vue'));
});
</script>
