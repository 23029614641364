<!-- 28-9-2 -->
<template>
  <div class="mt-40">
    <st-box>
      <div class="flex items-center">
        <span class="flex-1 text-xl font-bold">{{
          $t('studio.prj_prod_mngmt.rating_build_review.self_review.see_history.review_info_title')
        }}</span>
        <div class="shrink-0 flex items-center">
          <dropdown
            v-model="sortByValue"
            :options="filterDataStorageOptions"
            :dropdownBtnClass="'justify-end'"
            :dropdownProps="{
              size: 'sm',
              variant: 'text',
              distance: 0,
              offset: [0, 0],
              placement: 'bottom-end',
              class: 'min-w-[10.5rem]',
              itemDropdownClass: 'min-w-180'
            }"
            @update:modelValue="handleChangeSortBy()"
          />
        </div>
      </div>
      <div v-if="ratingHistory.length > 0">
        <div v-for="rating in ratingHistory" :key="rating.historyId">
          <div
            v-if="SUPPORT_RATING_TYPE_HISTORY.includes(rating.type)"
            class="mt-24 border-t-1 border-solid border-border pt-24"
          >
            <rating-history-item :rating="rating" />
          </div>
        </div>
      </div>
      <div v-else class="flex mt-24 justify-center items-center min-h-[27.2rem]">
        <p class="text-md leading-lg text-on-surface-elevation-2 text-center">
          {{ $t('studio.prj_prod_mngmt.rating_build_review.see_history.case_no_data_msg') }}
        </p>
      </div>
    </st-box>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useRoute } from 'vue-router';

import { definePageMeta } from '@/.nuxt/imports';
import { fetchUsageRatingHistoryApi } from '@/apis/rating.api';
import StBox from '@/components/common/st-box.vue';
import RatingHistoryItem from '@/components/rating/rating-history-item.vue';
import Dropdown from '@/components/validation/dropdown.vue';
import { SORT_BY } from '@/constants/common.const';
import { SUPPORT_RATING_TYPE_HISTORY } from '@/constants/rating.const';
import type { IRatingHistoryItem } from '@/types/rating/rating.response.type';

definePageMeta({
  middleware: 'handle-product-data-middleware',
  pageTitle: 'studio.prj_prod_mngmt.rating_build_review.self_review.see_history_pg_title'
});

const route = useRoute();

const productNo = route.params.productId as string;

const ratingHistory = ref<IRatingHistoryItem[]>([]);
const sortByValue = ref(SORT_BY.NEWEST);

const filterDataStorageOptions = [
  {
    value: SORT_BY.NEWEST,
    label: 'studio.prj_prod_mngmt.rating_build_review.self_review.see_history.dropbox_latest_order'
  },
  {
    value: SORT_BY.OLDEST,
    label: 'studio.prj_prod_mngmt.rating_build_review.self_review.see_history.dropbox_oldest_order'
  }
];

const handleChangeSortBy = () => {
  ratingHistory.value = ratingHistory.value.sort((a: IRatingHistoryItem, b: IRatingHistoryItem) => {
    if (sortByValue.value === SORT_BY.NEWEST) {
      return a.createdAt < b.createdAt ? 1 : -1;
    }

    return a.createdAt > b.createdAt ? 1 : -1;
  });
};

const init = async () => {
  const res = await fetchUsageRatingHistoryApi(productNo);

  if (res) {
    ratingHistory.value = res.histories;
  }
};

init();
</script>
